<template>
  <v-container fluid>
    <v-row>
      <v-col sm="9" class="pa-2">
        <v-card height="100%">
          <v-card-text>
            <v-row>
              <v-col class="xs" sm="6" cols="12" md="6" lg="4">
                <div class="subtitle-1 mb-2">
                  Emergency details
                  <router-link tag="a" to="/main-form?step=4">
                    <v-icon size="16">mdi-pencil</v-icon>
                  </router-link>
                </div>

                <div>Primary contact</div>
                <div
                  v-if="primaryContact!=undefined && primaryContact!==null"
                >{{primaryContact.firstName}} {{primaryContact.familyName}}</div>
                <div
                  v-if="primaryContact!=undefined && primaryContact!==null  &&  primaryContact.workPhone!=null"
                >
                  <v-icon size="16">mdi-phone</v-icon>
                  &nbsp;{{primaryContact.workPhone}}
                </div>
                <div
                  v-if="primaryContact!=undefined && primaryContact!==null   &&  primaryContact.mobilePhone!=null"
                >
                  <v-icon size="16">mdi-cellphone-basic</v-icon>
                  &nbsp;{{primaryContact.mobilePhone}}
                </div>

                <br />
                <div>Secondary contact</div>

                <div
                  v-if="secondaryContact!=undefined && secondaryContact!==null  "
                >{{secondaryContact.firstName}} {{secondaryContact.familyName}}</div>
                <div
                  v-if="secondaryContact!=undefined && secondaryContact!==null &&  secondaryContact.workPhone!=null"
                >
                  <v-icon size="16" @click="snackbar = false">mdi-phone</v-icon>
                  &nbsp;{{secondaryContact.workPhone}}
                </div>
                <div v-if="secondaryContact!=undefined && secondaryContact!==null  ">
                  <v-icon size="16" @click="snackbar = false">mdi-cellphone-basic</v-icon>
                  &nbsp;{{secondaryContact.mobilePhone}}
                </div>

                <div align="center"></div>
              </v-col>

              <v-col class="xs" sm="6" cols="12" md="6" lg="4">
                <div class="subtitle-1 mb-2">
                  Plan details
                  <v-icon size="16" @click.stop="dialog=true">mdi-pencil</v-icon>
                </div>

                <v-dialog v-model="dialog" width="600">
                  <plan-details @close="closePlanDetailsDialog"></plan-details>
                </v-dialog>

                <div
                  v-if="currentCase.planDetails!=undefined"
                >Plan start: {{currentCase.planDetails.planStart}}</div>
                <div
                  v-if="currentCase.planDetails!=undefined"
                >Plan end: {{currentCase.planDetails.planEnd}}</div>
                <div
                  v-if="currentCase.planDetails!=undefined"
                >Next review date: {{currentCase.planDetails.nextReviewDate}}</div>
              </v-col>

              <v-col class="xs" sm="6" cols="12" md="6" lg="4">
                <div class="subtitle-1 mb-2">Documentation checklist</div>

                <v-list class="documentation-checklist pa-0">
                  <v-list-item>
                    <v-list-item-action class="ma-0">
                      <v-icon v-if="!this.currentCase.isNdisPlanUploaded">mdi-circle-outline</v-icon>
                      <v-icon
                        color="primary"
                        v-if="this.currentCase.isNdisPlanUploaded"
                      >mdi-check-circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="!this.currentCase.isNdisPlanUploaded">NDIS plan</span>
                        <a
                          v-if="this.currentCase.isNdisPlanUploaded && currentCase.ndisPlan"
                          v-bind:href="currentCase.ndisPlan.downloadURL"
                          target="_blank"
                        >NDIS plan</a>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <upload
                        v-bind:path="ndisPlanPath"
                        :text="true"
                        v-on:uploadComplete="ndisPlanUploaded"
                      ></upload>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action class="ma-0">
                      <v-icon v-if="!this.currentCase.isServiceAgreementUploaded">mdi-circle-outline</v-icon>
                      <v-icon
                        color="primary"
                        v-if="this.currentCase.isServiceAgreementUploaded"
                      >mdi-check-circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="!this.currentCase.isServiceAgreementUploaded ">Service agreement</span>
                        <a
                          v-if="this.currentCase.isServiceAgreementUploaded && currentCase.serviceAgreement"
                          v-bind:href="currentCase.serviceAgreement.downloadURL"
                          target="_blank"
                        >Service agreement</a>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <upload
                        :text="true"
                        v-bind:path="serviceAgreementPath"
                        v-on:uploadComplete="serviceAgreementUploaded"
                      ></upload>
                    </v-list-item-avatar>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action class="ma-0">
                      <v-icon v-if="!this.currentCase.isSupportPlanUploaded">mdi-circle-outline</v-icon>
                      <v-icon
                        color="primary"
                        v-if="this.currentCase.isSupportPlanUploaded"
                      >mdi-check-circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span v-if="!this.currentCase.isSupportPlanUploaded">Support plan</span>
                        <a
                          v-if="currentCase.supportPlan && this.currentCase.isSupportPlanUploaded"
                          v-bind:href="currentCase.supportPlan.downloadURL"
                          target="_blank"
                        >Support plan</a>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <upload
                        v-bind:path="supportPlanPath"
                        :text="true"
                        v-on:uploadComplete="supportPlanUploaded"
                      ></upload>
                    </v-list-item-avatar>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <router-link
                          tag="a"
                          to="/participant-management/documents"
                        >Additonal documents</router-link>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col sm="3" class="pa-2">
        <v-card class="text-center pt-3" height="100%">
          <v-card-text class="text-center" style="padding-top :0px">
            <v-avatar size="80px">
              <v-img
                v-if="currentCase.participantDetails.photo!=undefined && currentCase.participantDetails.photo.downloadURL"
                v-bind:src="currentCase.participantDetails.photo.downloadURL"
                alt="Avatar"
                aspect-ratio="1"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-icon
                v-if="currentCase.participantDetails.photo!=undefined && !currentCase.participantDetails.photo.downloadURL"
                color="primary darken-2"
                size="100"
              >mdi-account-circle</v-icon>
            </v-avatar>
            <upload
              :text="true"
              v-bind:path="avatarPath"
              style="display:block"
              v-on:uploadComplete="avatarUploaded"
            ></upload>
            <span class="card-title font-weight-light">
              {{currentCase.participantDetails.firstname}}
              {{currentCase.participantDetails.lastname}}
            </span>
            <h6>NDIS number: {{currentCase.participantDetails.nDISNumber}}</h6>
            <h6
              class="category text-gray font-weight-thin mb-3"
            >{{currentCase.participantDetails.email}}</h6>
            <div>
              <router-link
                tag="a"
                to="/participant-management/current-participant/about-me"
              >About me</router-link>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2">
        <router-link tag="a" to="/main-form">
          <material-stats-card
            color="primary"
            icon="mdi-file-account"
            title="Participant Details"
            value
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2">
        <router-link tag="a" to="/goals">
          <material-stats-card
            color="primary"
            icon="mdi-notebook"
            title="Goals/Progress notes"
            value
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2">
        <router-link tag="a" to="/general-notes">
          <material-stats-card
            color="primary"
            icon="mdi-calendar-text-outline"
            title="General Notes"
            value
            sub-icon="mdi-tag"
            sub-text
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2">
        <router-link tag="a" to="/individual-risk-assessments">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <material-stats-card
                slot="activator"
                color="primary"
                icon="mdi-chart-line"
                title="Risk assessments"
                value
                v-on="on"
                sub-icon="mdi-update"
                sub-text
              />
            </template>
            <span>Add a new Individual Risk Assessement</span>
          </v-tooltip>
        </router-link>
      </v-col>

      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2">
        <router-link tag="a" to="/participant-management/current-participant/videos">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <material-stats-card
                slot="activator"
                color="primary"
                icon="mdi-video-outline"
                title="Videos"
                value
                v-on="on"
                sub-icon="mdi-update"
                sub-text
              />
            </template>
            <span>Upload Video evidence</span>
          </v-tooltip>
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2" v-if="isAdmin">
        <router-link tag="a" to="/participant-management/current-participant/invoicing-billing">
          <material-stats-card
            color="primary"
            icon="mdi-currency-usd"
            title="Invoicing and billing"
            value
            sub-icon="mdi-update"
            sub-text
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2">
        <router-link tag="a" to="/participant-management/reporting/reports">
          <material-stats-card
            color="primary"
            icon="mdi-information-outline"
            title="Reports"
            value
            sub-icon="mdi-tag"
            sub-text
          />
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2">
        <router-link tag="a" to="/participant-management/incidents/incidents">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <material-stats-card
                slot="activator"
                color="primary"
                icon="mdi-alert"
                title="Incidents"
                value
                v-on="on"
                sub-icon="mdi-update"
                sub-text
              />
            </template>
            <span>Immediate or 5 Day Incident Reports</span>
          </v-tooltip>
        </router-link>
      </v-col>
      <v-col sm="6" cols="12" md="6" lg="3" class="pa-2">
        <router-link tag="a" to="/participant-management/alerts">
          <material-stats-card
            color="primary"
            icon="mdi-bell-alert"
            title="Alerts"
            :value="numberOfAlerts"
            value-color="red"
            sub-icon="mdi-tag"
          />
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import planDetails from "./plan-details";
import * as alertsService from "../../services/alertsService";
export default {
  $_veeValidate: {
    validator: "new"
  },
  name: "case-details",
  components: {
    "plan-details": planDetails
  },
  data: () => ({
    vm: {},
    dialog: false,
    avatarPath: "",
    ndisPlanPath: "",
    serviceAgreementPath: "",
    supportPlanPath: "",
    additionalInfoPath: "",
    numberOfAlerts: "0",
    isAdmin: false
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    user() {
      return this.$store.getters.user;
    },
    primaryContact() {
      if (
        this.currentCase.contacts != undefined &&
        this.currentCase.contacts.length > 0
      ) {
        return this.currentCase.contacts.find(contact => {
          return contact.contactType === "Primary";
        });
      } else {
        return null;
      }
    },
    secondaryContact() {
      if (
        this.currentCase.contacts != undefined &&
        this.currentCase.contacts.length > 0
      ) {
        return this.currentCase.contacts.find(contact => {
          return contact.contactType === "Secondary";
        });
      } else {
        return null;
      }
    }
  },
  methods: {
    init() {
      if (this.currentCase.planDetails === undefined) {
        this.currentCase.planDetails = {};
      }
      let basePath =
        this.currentOrganisation.id + "/" + this.currentCase.id + "/";
      this.avatarPath = basePath + "avatar/";
      this.ndisPlanPath = basePath + "ndisPlan/";
      this.serviceAgreementPath = basePath + "serviceAgreement/";
      this.supportPlanPath = basePath + "supportPlan/";
      this.additionalInfoPath = basePath + "additionalInfo/";
      if (
        this.user.roles.indexOf("System Administrator") > -1 ||
        this.user.roles.indexOf("Organisation Administrator") > -1
      ) {
        this.isAdmin = true;
      }
      this.countAlerts();
    },
    avatarUploaded(data) {
      this.currentCase.participantDetails.photo = data;
      this.$store.dispatch("setCurrentCase", this.currentCase);
      this.$forceUpdate();
    },
    ndisPlanUploaded(data) {
      this.currentCase.isNdisPlanUploaded = true;
      this.currentCase.ndisPlan = data;
      this.$store.dispatch("setCurrentCase", this.currentCase);
      this.$forceUpdate();
    },
    serviceAgreementUploaded(data) {
      this.currentCase.isServiceAgreementUploaded = true;
      this.currentCase.serviceAgreement = data;
      this.$store.dispatch("setCurrentCase", this.currentCase);
      this.$forceUpdate();
    },
    supportPlanUploaded(data) {
      this.currentCase.isSupportPlanUploaded = true;
      this.currentCase.supportPlan = data;
      this.$store.dispatch("setCurrentCase", this.currentCase);
      this.$forceUpdate();
    },
    countAlerts() {
      const me = this;
      alertsService
        .countAlerts(this.currentOrganisation.id, this.currentCase.id)
        .then(querySnapshot => {
          me.numberOfAlerts = new String(querySnapshot.size);
        })
        .catch(err => console.error(err));
    },
    closePlanDetailsDialog() {
      this.dialog = false;
      setTimeout(() => {
        this.vm = {};
      }, 300);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss"  >
.documentation-checklist {
  .v-list-item {
    height: 14px !important;
    padding: 0 0 0 0;
    min-height: 38px;
  }
  .v-list-item__action,
  .v-list-item__avatar {
    min-width: 30px;
  }
  .v-list-item__content {
    padding: 0 0 0 0;
  }

  .v-card--material-stats:hover {
  border-top: 4px solid #156a59;
}
}
</style>

